.form-container {
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    gap: 5%;
  }
  .block {
    width: 100%;
  }
  .button-container {
    padding-top: 30px;
     display: flex;
    justify-content: flex-end;
  }
}
